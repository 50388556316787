.autosave-in-primary-button {
  padding: 2px !important;
  & > *,
  & > svg {
    color: white !important;
  }
}

.mandatory {
  color: red;
}

.dialog-with-select-input {
  min-width: 350px;
  overflow-y: initial !important;
  & form > div {
    overflow-y: initial !important;
  }
}
