@import "../../../../mixins";

.appButton {
  width: 100%;
}
.appContent{
  float: left;
}
.appName{
  word-break: break-all;
  font-size: 22px;
}
.appIcon{
  top: 0;
  left: 5px;
  position: absolute;
  font-size: 30px;
}
.appAspireCode{
  float: left;
  margin-left: 10px;
}
.app-card-title {
  & > svg {
    margin-right: 0.2rem;
    height: 2rem;
    width: 2rem;
  }
}
