@import "../../../../mixins";

.teamButton {
  width: 100%;
}
.teamContent{
  float: left;
}
.teamName{
  word-break: break-all;
  font-size: 22px;
}
.teamIcon{
  top: 0;
  left: 5px;
  position: absolute;
  font-size: 30px;
}
.team-card-title {
  & > svg {
    margin-right: 0.2rem;
    height: 2rem;
    width: 2rem;
  }
}
