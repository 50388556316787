.newPortalBannerButton {
    background: none;
	border: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	cursor: pointer;
	text-decoration: underline;
	padding: 0;
}
