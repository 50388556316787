@import "./common";

html,
body {
  height: 100%;
}
.login-pages{
  background-image: url("carbon.svg#svgView(viewBox(0, 32, 32, 32))");
  height: 100vh;
  width: 300vh;
}
.connexion-app {
  position: absolute;
  left: 50%;
  top: 42%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.toolbar-header {
  float: left;
  svg {
    transform: scale(1.8);
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.toolbar-action {
  float: right;
}
.toolbar-action > * { margin-right: 5px; }

.App {
  font-size: 13px;

  & a {
    color: black;
    text-decoration: none;
  }

  & a:hover {
    color: #00a9e0;
    text-decoration: none;
  }

  & .disabled-link {
    pointer-events: none;
  }

  .icon-page {
    transform: scale(1.8);
    margin-right: 1rem;
  }

  a.email {
    color: #0000ee;
    text-decoration: underline;
  }
}
