.metric-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.metric-content {
  text-align: center;
  margin: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.metric-label {
  font-size: 1rem;
}

.metric-value {
  font-size: 2.3rem;
  font-weight: bold;
}
