.custom-progress-bar {
  z-index: 0;
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #ccc;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.custom-filler {
  background: #0085ad;
  position: absolute;
  height: 100%;
  border-radius: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: width 0.2s ease-in;
}

.custom-filler-primary {
  background: #0085ad;
}

.custom-filler-warning {
  background: #fc5500;
}

.custom-filler-danger {
  background: #f33;
}

.custom-filler-success {
  background: #5cb85c;
}

.custom-filler-critical {
}

.custom-progress-content {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  color: white;
  z-index: 1;
  padding-left: 2px;
  padding-right: 2px;
}

.custom-progress-content-critical {
  background: #c60000;
}
