@import "./../../../../mixins";

.accountCardContent {
  float: left;
}
.accountButton{
  width: 100%;
}
.accountCardName {
  font-size: x-large;
}

@media screen and (min-width: 200px) and (max-width: 1400px) {
  .accountCardContent {
    float: none;
  }
  .accountCardName {
    font-size: 22px;
  }
}

.accountCardType{
  margin-left: 10px;
  font-size: 15px;
}

.hr {
  text-align: center;
}

.hr img {
  position: relative;
  top: -48px;
  width: 80px;
  padding:0 5px;
}
