.select-app {
  width: 250px;
  color: black;
  z-index: 9999;
  margin-bottom: 0 !important;
}

.select-account {
  width: 250px;
  color: black;
  margin-bottom: 0 !important;
}

.pcp-header-user {
  margin-right: 1rem;
  cursor: pointer;
}

.pcp-header {
  & > div {
    padding-left: 0;
    padding-right: 0;
  }
}

$mdSize: 1300px;
$smSize: 1090px;
$xsSize: 640px;

.pcp-header-title {
  color: white;
  margin-bottom: 0;
  cursor: pointer;
  min-width: 36rem;
  & .logo {
    height: 1rem;
    width: 8rem;
    vertical-align: middle;
  }

  & .version {
    color: white;
  }

  @media screen and (max-width: $mdSize) {
    min-width: 24rem;
  }

  @media screen and (max-width: $smSize) {
    min-width: 18rem;
    margin-left: 1rem;
    & .version {
      display: none;
    }
  }

  @media screen and (max-width: $xsSize) {
    min-width: 10rem;
    margin-left: 1rem;
    & .logo {
      display: none;
    }
  }
}

.pcp-header-tools {
  position: relative;
  margin-left: 0;
  width: 100%;
  text-align: right;
  & > * {
    display: inline-block;
    margin-left: 0.5rem;
    text-align: left;
    vertical-align: middle;
  }
  & > *:first-child {
    margin-left: 0;
  }

  .pcp-header-admin-toggle {
    & label {
      color: white;
    }
    @media screen and (max-width: $smSize) {
      display: none;
    }
  }

  @media screen and (max-width: $smSize) {
    .select-app {
      display: none;
    }
  }
}
