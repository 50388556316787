.cell_state_READY {
  color: green;
}

.cell_state_IN_PROGRESS {
  color: rgb(45, 121, 227);
}

.cell_state_IN_ERROR {
  color: red;
}

.cell_state_USED {
  color: #227777;
}

.cell_criticality_LOW,
.cell_severity_LOW,
.cell_encryption_level_LOW {
  color: green;
}
.cell_criticality_HIGH,
.cell_severity_HIGH,
.cell_encryption_level_HIGH {
  color: red;
}

.row-filter {
  height: 20px;
  background-color: #efefef !important;
}

.cell-filter {
  height: 20px;
  & > div {
    margin-bottom: 0px;
  }
  & .checked-list-filter {
    min-width: 180px;
    max-width: 250px;
  }
}

$apiTableMinHeight: 400px;
.api-table {
  min-height: $apiTableMinHeight;
  & > div {
    min-height: $apiTableMinHeight;
  }
}

.actions-cell {
  min-width: 200px;
}
