.criticality-LOW {
  color: green;
}
.criticality-HIGH {
  color: red;
}

.app-card-summary-container {
  & > div {
    margin-left: 0;
    & > div > div:first-child {
      text-align: right;
    }
  }
  & div {
    margin-bottom: 0.4rem !important;
  }
}

.app-card-summary-title {
  min-height: 2.4rem;
}

.app-card-summary-title-admin {
  min-height: 5.5rem;
}

.appCard-toolbar {
  text-align: right;
}
